<template>
  <div class="middle-box text-center loginscreen animated fadeInDown">
    <div>
      <h1 class="logo-name">
        {{ this.title }}
      </h1>
      <h3> Bem-vindo ao {{ title }} </h3>
      <p> Projetado para composição de orçamentos </p>
    </div>

    <login-form v-if="showing == 'login'"
                :options="{login_url: this.login_url}"
    >
    </login-form>
    <signup-form v-if="showing == 'signup'">
    </signup-form>
    <remember-form v-if="showing == 'remember'"
                   :options="{login_url: this.login_url}">
    </remember-form>

  </div>
</template>

<script>

import LoginForm      from './login_form.vue';
import SignUpForm      from './signup_form.vue';
import RememberForm   from './remember_form.vue';

export default {
  props: ['options'],
  components: {
      'login-form':    LoginForm,
      'signup-form':    SignUpForm,
      'remember-form':    RememberForm
  },
  data: function() {
    return {
      showing: 'login',

      title: '',  // this.options.title,

      auth_token: '',
      login_url: '/login',
      signup_url: '/signup/sign_up'

      // email: 'gustavo@43t.com.br',
      // password: '963258741'
    }
  },
  methods: {
    setSignup: function () {
      this.showing = 'signup'
    },
    setLogin: function () {
      this.showing = 'login'
    }
  },
  created: function() {
    // this.token = this.$store.getters['CurrentUserStore/token']

    console.log('DeviseIndex created!')
    this.auth_token = document.querySelector("meta[name=csrf-token]").content
  },
  mounted() {
    this.title = this.options.title ;
    console.log('DeviseIndex mounted!')
  }

}
</script>
