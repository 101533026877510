<template>
  <div class="">
    <div class="ibox-content">
      <form class="m-t" role="form"
            method="post"
            :action="rememberUrl()">

        <input name="utf8" type="hidden" value="✓">
        <input type="hidden" name="authenticity_token"
               :value="opts.form_token">

        <div class="form-group">
          <label class="string required" id="label_user_email"
                 for="user_email"
                 style="width: 100%;text-align: left !important;"
          >Informe seu email para envio de instruções
          </label>
          <input type="email" class="form-control"
                 placeholder="Digite seu email" required=""
                 id="user_email"
                 name="user[email]"
          >
        </div>

        <button type="submit"
                class="btn btn-primary block full-width m-b"
        >Cadastrar nova senha</button>

        <a @click="showLogin">
          <small>Fazer Login</small>
        </a>

      </form>

    </div>
  </div>
</template>

<script>

export default {
  components: {
    // Layout
  },

  data: function() {
    return {
      email: 'gustavo@43t.com.br',
      password: '963258741',
      opts: {
        login_url: '',
        form_token: ''

      }
    }
  },
  created: function() {
    this.opts.form_token = document.querySelector("meta[name=csrf-token]").content
  },
  methods: {
    showLogin: function() {
      this.$parent.showing = 'login'
    },
    rememberUrl: function() {
      return this.$parent.remember_url
    }
  }

}
</script>
